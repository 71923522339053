const vcbioticDescriptionEN = [
  "QNE VCBIOTIC 🍊Orange flavored 【Enzyme Detox】【100% Natural Ingredients】No need to brew 【Directly pour into mouth】【Dissolve immediately】【Super convenient】You can take it anytime, anywhere. 【Constipation】【Edema】【Obesity】【Big belly】【Thick arms】【Thick thighs】【Dark skin】【Acne face】will【gradually disappear】Learn more about the ingredients and other functions of this product, which will definitely make you more excited. ", " ",
  "【11 Types of Excellent Ingredients & Functions】", " ",
  "🍊 Orange Powder: Provide high volume antioxidants and minerals; enhance Immunity ; promote intestinal peristalsis and lower cholesterol; promote blood lipids and blood pressure.", " ",
  "🥥 Psyllium Seed Husk Powder: Improve lubrication conditions of feces and intestinal wall; promote speed of defecation; increase dry weight of feces; relieve constipation and diarrhea.", " ",
  "🥬 Soluble Fiber: Increase fiber content; prevent constipation.", " ",
  "🍎 Apple Fiber: Promote digestive regularity and healthy intestinal tract; relieve constipation; provide satiety; detoxification; prevent fat absorption; reduce cholesterol; strengthen immune system.", " ",
  "🍓 Raspberry Ketone: Promote the breakdown of lipids in fat cells; prevent the increase of fat in the liver; prevent the increase of visceral fat in the surrounding organs.", " ",
  "🍃 Green Tea Extract: Increase fat burning and metabolic rate; reduce infection and prevent cell damage.", " ",
  "🍑 Red Orange Extract: Lower cholesterol level; inhibit fat production; prevent fat accumulation in the body.", " ",
  "🍈 Inulin: Prebiotic fiber; promote gastrointestinal health.", " ",
  "🫐 Digezyme: Break down proteins, cellulose, starches and other food stuff into smaller molecules in order to ease absorption of nutrients.", " ",
  "🥕 Vitamin C: Combat free radicals; repair and regenerate tissues; protect skin health; enhance the immune system; improve overall health.", " ",
  "🍒 Probiotic: Improve the balance of microbes in the intestine; adjust the composition of intestinal bacteria to inhibit bacteria.", " ",
  "✅ KKM certification ✅ HALAL certification ✅ ISO certification ✅ GMP certification ✅ HACCP certification ✅ MESTI certification", " ",
  "【1 Box x 15 sachets】",
  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM60",
  "✔️ 2 boxes RM110",
  "✔️ 4 boxes RM210",
  "✔️ 6 boxes RM310",
  "✔️ 8 boxes RM410",
  "✔️ 10 boxes RM510", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const vcbioticDescriptionCN = [
  "QNE VCBIOTIC 🍊 橙子口味的【酵素排毒】【100% 纯天然】不需要冲泡 【直接倒入嘴里】 【立马融化】【超级方便】随时随地都可以服用。服用之后 【便秘】【水肿】【肥胖】【大肚腩】【粗手臂】【粗大腿】【皮肤暗淡】【痘痘脸】都将【慢慢的离你而去】【一去不复返】心动了的话就往下了解此产品的成分和其他功效，绝对会让你更心动。", " ",
  "【11 种高品质的成分和功效】", " ",
  "🍊 橙子粉：提供大量抗氧化剂和矿物质、增强免疫力、促肠道蠕动、降低胆固醇、促进血脂和血压。", " ",
  "🥥 车前子壳粉：改善粪便和肠壁的润滑条件、促进排便速度、增加粪便的干重、缓解便秘和腹泻。", " ",
  "🥬 可溶性纤维：增加纤维含量、预防便秘。", " ",
  "🍎 苹果纤维：促进消化规律和肠道的健康、缓解便秘、提供饱腹感、排毒、预防脂肪吸收、降低胆固醇、增强免疫系统。", " ",
  "🍓 山莓：促进脂肪细胞中的脂质分解、防止肝脏脂肪增加、防止周围器官内脏脂肪的增加。", " ",
  "🍃 绿茶提取物：增加脂肪燃烧和代谢率、减少感染、防止细胞受损。", " ",
  "🍑 红橙提取物：降低胆固醇水平、抑制脂肪的产生、防止体内脂肪堆积。", " ",
  "🍈 菊粉：益生元纤维、促进肠胃健康。", " ",
  "🫐 消化酶：分解蛋白质、纤维素、淀粉和其他食物成更小的分子，以减轻营养物质的吸收。", " ",
  "🥕 维生素 C：对抗自由基、修复和再生组织、保护皮肤健康、增强免疫系统、改善整体健康。", " ",
  "🍒 益生菌： 改善肠道微生物的平衡、调整肠道细菌的成分和抑制细菌。		", " ",
  "✅ KKM 认证 ✅ HALAL 认证✅ ISO 认证 ✅ GMP 认证 ✅ HACCP 认证 ✅ MESTI 认证", " ",
  "【1 盒有 15 小包装】",
  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM60",
  "✔️ 2 盒 RM110",
  "✔️ 4 盒 RM210",
  "✔️ 6 盒 RM310",
  "✔️ 8 盒 RM410",
  "✔️ 10 盒 RM510", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];


const vcmmuneDescriptionEN = [
  "QNE VCMMUNE 🍑 Peach flavored【Immune Booster + Tiger Milk Mushroom + Vitamin C】【100% Natural Ingredients】No need to brew 【Directly pour into mouth】 【Dissolve immediately】【Super convenient】You can take it anytime, anywhere.【Weak】【Cold & Flu】【Cough】【Sore throat】【Nasal allergy】【Asthma】【High blood sugar】【High blood pressure】【High cholesterol】【Get tired easily】will【gradually disappear】Learn more about the ingredients and other functions of this product, which will definitely make you more excited. ", " ",
  "【11 Types of Excellent Ingredients & Functions】", " ",
  "🍑 Peach Powder: Provide high antioxidants for a stronger immune system; protect body against aging and illnesses, including cancer; promote heart health, eye health and skin health; reduce inflammation.", " ",
  "🍄 Tiger Milk Mushroom: Helps treat lung and respiratory diseases; reduce respiratory and airway inflammation; relieves cough and phlegm; alleviates asthma and sinus symptoms.", " ",
  "🪷 Milk Thistle: Prevent harmful toxins from entering the liver; helps remove harmful substances from the liver cells.", " ",
  "🥒 Bitter Melon Peptide: Lower blood sugar, reduce cholesterol and fatty acid synthesis; lower triglycerides and LDL cholesterol; reduce the incidence of heart disease.", " ",
  "🫘 Soy Oligopeptide: Supplement human nitrogen source and restore physical strength; relieve fatigue, improve mood, and lower blood pressure; reduce blood lipid, lower blood sugar and reduce blood viscosity; promote fat metabolism and energy metabolism.", " ",
  "🪴 Licorice: Tonifying spleen and nourishing stomach; relieve dysmenorrhea; high antioxidants and boost immunity.", " ",
  "🌿 Plant Sterol: Reduce the body's ability to absorb cholesterol; lower total cholesterol and reduce the risk of CVD.", " ",
  "🥜 Peanut Root Powder: Improve gut health; strengthening vitality and improving blood circulation.", " ",
  "🍷 *O2 Premix: Provide and supplement with antioxidants.", " ",
  "🍊 Vitamin C: Combat free radicals; repair and regenerate tissues; protect skin health; enhance the immune system; improve overall health.", " ",
  "🧃 *Vitamin & Mineral Premix: Provides and replenishes vitamins and minerals.", " ",
  "✅ KKM certification ✅ HALAL certification ✅ ISO certification ✅ GMP certification ✅ HACCP certification ✅ MESTI certification", " ",
  "【1 Box x 15 sachets】",
  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM65",
  "✔️ 2 boxes RM120",
  "✔️ 4 boxes RM220",
  "✔️ 6 boxes RM320",
  "✔️ 8 boxes RM420",
  "✔️ 10 boxes RM520", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const vcmmuneDescriptionCN = [
  "QNE VCMMUNE 🍑 桃子口味的【增强免疫系统 + 虎乳芝 + 维生素 C】【100% 纯天然】不需要冲泡 【直接倒入嘴里】 【立马融化】【超级方便】随时随地都可以服用。服用之后 【体弱多病】【伤风感冒】【咳嗽】【喉咙痛】【鼻腔敏感】【哮喘】【高血糖】【高血压】【高胆固醇】【容易疲累】都将【慢慢的离你而去】【一去不复返】心动了的话就往下了解此产品的成分和其他功效，绝对会让你更心动。", " ",
  "【11 种高品质的成分和功效】", " ",
  "🍑 桃粉：提供高抗氧化、增强免疫系统、保护身体免受衰老和疾病、包括癌症、促进心脏健康、眼睛健康和皮肤健康、 减少炎症。", " ",
  "🍄 虎乳芝：帮助治疗肺部和呼吸道疾病、 减少呼吸道和气道炎症、具有止咳化痰功效、缓解哮喘和鼻窦症状。", " ",
  "🪷 奶蓟草：防止有害毒素进入肝脏、帮助肝细胞清除有害物质。", " ",
  "🥒 苦瓜肽：降低血糖、减少胆固醇和脂肪酸的合成、降低甘油三酯和低密度脂蛋白胆固醇、减少心脏病的发病率。", " ",
  "🫘 大豆寡肽：补充人体氮源、恢复体力、缓解疲劳、改善情绪、降低血压、降低血脂、降低血糖、降低血液粘度、促进脂肪代谢和能量代谢。", " ",
  "🪴 甘草：具有补脾养胃功效、缓解痛经、富含抗氧化剂、增强免疫力。", " ",
  "🌿 植物甾醇：降低身体吸收胆固醇的能力、降低总胆固醇、降低心血管疾病的风险。", " ",
  "🥜 花生根粉：改善肠道健康、增强元气和活力、促进血液循环。", " ",
  "🍷 *O2 预混料：提供和补充抗氧化。", " ",
  "🍊 维生素 C：对抗自由基、修复和再生组织、保护皮肤健康、增强免疫系统。", " ",
  "🧃 *维生素和矿物质预混料：提供并补充维生素和矿物质。", " ",
  "【1 盒有 15 小包装】",
  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM65",
  "✔️ 2 盒 RM120",
  "✔️ 4 盒 RM220",
  "✔️ 6 盒 RM320",
  "✔️ 8 盒 RM420",
  "✔️ 10 盒 RM520", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];


const vcllagenDescriptionEN = [
  "QNE VCLLAGEN 🍋 Lemon flavored【Collagen Peptide  + Whitening + Anti Aging】【100% Natural Ingredients】No need to brew 【Directly pour into mouth】 【Dissolve immediately】【Super convenient】You can take it anytime, anywhere.【Dark skin】【Sagging skin】【Acne skin】【Wrinkles】【Scars】【Freckles】【Dark spots】【Age spots】will【gradually disappear】Learn more about the ingredients and other functions of this product, which will definitely make you more excited. ", " ",
  "【9 Types of Excellent Ingredients & Functions】", " ",
  "🍋 Lemon Powder: It has antioxidant properties to prevent skin aging; helps reduce inflammation; boosting the immune system.", " ",
  "🐟 Fish Collagen Peptide: Promote body collagen, anti-aging, and wrinkle resistance; promote wound healing and regeneration; antioxidant protection against free radicals that cause cell damage; promotes bone growth and healing.", " ",
  "☘️ Tremella Fuciformis Extract: Helps hydrate skin and reduce wrinkles; vitamin D helps regulate cell growth and protects us against cancer.", " ",
  "🍎 Pomegranate Extract: High antioxidants help protect your body's cells from damage; improve skin elasticity and delay aging; helps fight skin inflammation and whitening; helps fight inflammation and slow cancer cell growth.", " ",
  "🌸 Sakura Extract: Rich in fatty acids helps to promote smooth and supple skin; contains skin brightening properties; combats signs of aging and regenerating skin.", " ",
  "🫐 Blueberry Extract: Maintain healthy bones and promotes skin health; lower blood pressure and prevention of diabetes; prevent heart disease and reduce the risk of cancer; improving mental health and improving digestion.", " ",
  "🌾 Rice Peptide: Reduce skin irritation and UV damage; reduce lines, wrinkles, and hyperpigmentation; stimulates body collagen production; promotes skin hydration and skin lightening.", " ",
  "🍅 Lycopene: Powerful antioxidant that can prevent aging and helps skin whitening; helps to reduce the risk of heart disease and cancer.", " ",
  "🍊 Vitamin C: Combat free radicals; repair and regenerate tissues; protect skin health; enhance the immune system; improve overall health.", " ",
  "✅ KKM certification ✅ HALAL certification ✅ ISO certification ✅ GMP certification ✅ HACCP certification ✅ MESTI certification", " ",
  "【1 Box x 15 sachets】",
  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM65",
  "✔️ 2 boxes RM120",
  "✔️ 4 boxes RM220",
  "✔️ 6 boxes RM320",
  "✔️ 8 boxes RM420",
  "✔️ 10 boxes RM520", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const vcllagenDescriptionCN = [
  "QNE VCLLAGEN 🍋 柠檬口味的【胶原蛋白肽 + 美白 + 抗老】【100% 纯天然】不需要冲泡 【直接倒入嘴里】 【立马融化】【超级方便】随时随地都可以服用。服用之后 【暗淡肌肤】【下垂肌肤】【痘痘肌肤】【皱纹】【疤痕】【雀斑】【黑斑】【老人斑】都将【慢慢的离你而去】【一去不复返】心动了的话就往下了解此产品的成分和其他功效，绝对会让你更心动。", " ",
  "【9 种高品质的成分和功效】", " ",
  "🍋 柠檬粉： 具有抗氧化的特性、可以防止皮肤老化、有助于减少炎症、增强免疫系统。", " ",
  "🐟 鱼胶原蛋白肽：促进体内胶原蛋白、抗衰老、抗皱、促进伤口愈合和再生、抗氧化保护细胞免受自由基的伤害、促进骨骼生长和愈合。", " ",
  "☘️ 银耳提取物： 帮助滋润皮肤、减少皱纹、维生素 D 有助于调节细胞生长、保护我们免受癌症。", " ",
  "🍎 石榴提取物：高抗氧化有助于保护身体细胞免受伤害 、增强皮肤弹性、延缓衰老、帮助对抗皮肤炎症和美白、有助于对抗炎症和减缓癌细胞生长。", " ",
  "🌸 樱花提取物：富含脂肪酸、促进肌肤光滑柔软、含有美白提亮功效、对抗皮肤老化和再生的迹象。", " ",
  "🫐 蓝莓提取物：保持骨骼健康、促进皮肤健康、降低血压和预防糖尿病、预防心脏病、降低患癌症的风险、改善心理健康和促进消化。", " ",
  "🌾 米肽：减少对皮肤的刺激和紫外线伤害、减少细纹、皱纹和色素沉着、刺激体内胶原蛋白的生成、促进皮肤水分和皮肤亮白。", " ",
  "🍅 番茄红素粉：抗氧化、预防衰老、美白肌肤、有助减低患心脏病和癌症的风险。", " ",
  "🍊 维生素 C：对抗自由基、修复和再生组织、保护皮肤健康、增强免疫系统、改善整体健康状况。", " ",
  "【1 盒有 15 小包装】",
  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM65",
  "✔️ 2 盒 RM120",
  "✔️ 4 盒 RM220",
  "✔️ 6 盒 RM320",
  "✔️ 8 盒 RM420",
  "✔️ 10 盒 RM520", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];


const vcblockDescriptionEN = [
  "QNE VCBLOCK 🥝 Kiwi flavored【Carbohydrate Blocker + Fat Blocker + Fat Burner】【100% Natural Ingredients】No need to brew 【Directly pour into mouth】 【Dissolve immediately】【Super convenient】You can take it anytime, anywhere. 【Obesity】【Fat】【Edema】【Cellulite】【Big Belly】【Thick Arms】【Thick Thighs】【Visceral Fat】 will【gradually disappear】Learn more about the ingredients and other functions of this product.", " ",
  "【12 Types of Excellent Ingredients & Functions】", " ",
  "🥝 Kiwi Powder: High antioxidant; anti-inflammatory; benefit for digestive health; benefit for heart health. 	", " ",
  "🍑 Garcinia Cambogia Extract: Helps to inhibit the fat synthesis process; reduces appetite; block fat production; reduce belly fat; lower bad cholesterol and increase good cholesterol. ", " ",
  "🍒 Raspberry Ketone: Promote the breakdown of lipids in fat cells; prevent the increase of fat in the liver; prevent the increase of visceral fat in the surrounding organs.", " ",
  "🫐 Digezyme: Break down proteins, cellulose, starches and other food stuff into smaller molecules in order to ease absorption of nutrients.", " ",
  "🍈 Chitosan: Promote weight loss and improve cholesterol levels.", " ",
  "🥑 Probiotic (Bifidobacterium longum): Enhanced mood and relieved stress; promote digestive health and disease prevention.", " ",
  "🌵 Lotus Leaf Extract: Promote antioxidant and anti-inflammatory effects; promote antibacterial and lower blood sugar.", " ",
  "🌷 Saffron Stigma Extract: Improve mood and treat depressive symptoms; helps cancer-fighting and reduces PMS symptoms.", " ",
  "🍊 Red Orange Extract: Lower cholesterol level; inhibit fat production; prevent fat accumulation in the body.", " ",
  "🥜 White Kidney Bean Extract: Promote a healthy body weight; reduce carbohydrate digestion and absorption.", " ",
  "🥬 Probiotic (Bifidobacterium Breve): Promote a balanced gut microbiome; aids in diarrhea prevention and reduces digestive issues; reduce the severity of allergies and eczema; improve mental health and heart health.", " ",
  "🍅 Vitamin C: Combat free radicals; repair and regenerate tissues; protect skin health; enhance the immune system; improve overall health.	", " ",
  "✅ KKM certification ✅ HALAL certification ✅ ISO certification ✅ GMP certification ✅ HACCP certification ✅ MESTI certification ", " ",
  "【1 Box x 15 sachets】",
  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM70",
  "✔️ 2 boxes RM130",
  "✔️ 4 boxes RM250",
  "✔️ 6 boxes RM370",
  "✔️ 8 boxes RM490",
  "✔️ 10 boxes RM610", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const vcblockDescriptionCN = [
  "QNE VCBLOCK 🥝 奇异果口味的【阻隔淀粉 + 阻隔脂肪 + 燃烧脂肪 】【100% 纯天然】不需要冲泡 【直接倒入嘴里】 【立马融化】【超级方便】随时随地都可以服用。服用之后 【肥胖】【脂肪】【水肿】【赘肉】【大肚腩】【粗手臂】【粗大腿】【内脏脂肪】都将【慢慢的离你而去】【一去不复返】心动了的话就往下了解此产品的成分和其他功效，绝对会让你更心动。", " ",
  "【12 种高品质的成分和功效】", " ",
  "🥝 奇异果粉：提供抗氧化和抗炎作用、益于消化健康、益于心脏健康", " ",
  "🍑 藤黄果提取物：助于抑制脂肪合成过程，降低食欲、阻止脂肪生成并减少腹部脂肪、降低坏胆固醇和增加好胆固醇", " ",
  "🍒 山莓：促进脂肪细胞中的脂质分解、防止肝脏脂肪增加、防止周围器官内脏脂肪的增加", " ",
  "🫐 消化酶：分解蛋白质、纤维素、淀粉和其他食物成更小的分子，以减轻营养物质的吸收", " ",
  "🍈 壳聚糖：促进减肥和改善胆固醇水平", " ",
  "🥑 益生菌 （长双歧杆菌）：改善情绪并缓解压力、促进消化健康和疾病预防", " ",
  "🌵 荷叶提取物：促进抗氧化和抗炎作用、促进抗菌和降低血糖", " ",
  "🌷 藏红花柱头提取物：改善情绪并治疗抑郁症状、助于抗癌并减少 PMS 症状", " ",
  "🍊 红橙提取物：降低胆固醇水平、抑制脂肪的产生、防止体内脂肪堆积", " ",
  "🥜 白芸豆提取物：促进健康的体重、助于减少碳水化合物的消化和吸收", " ",
  "🥬 益生菌 （短双歧杆菌）：促进肠道微生物群平衡、助于预防腹泻并减少消化问题、助于减轻过敏和湿疹的严重程度、助于改善心理健康和心脏健康。", " ",
  "🍅 维生素 C：对抗自由基、修复和再生组织、保护皮肤健康、增强免疫系统、改善整体健康", " ",
  "✅ KKM 认证 ✅ HALAL 认证✅ ISO 认证 ✅ GMP 认证 ✅ HACCP 认证 ✅ MESTI 认证", " ",
  "【1 盒有 15 小包装】",
  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM70",
  "✔️ 2 盒 RM130",
  "✔️ 4 盒 RM250",
  "✔️ 6 盒 RM370",
  "✔️ 8 盒 RM490",
  "✔️ 10 盒 RM610", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];



const vcmasculiDescriptionEN = [
  "QNE VCMASCULI 🫐 Blueberry Flavored【Men’s Health】【100% Natural Ingredients】No need to brew 【Directly pour into mouth】【Dissolve immediately】【Super convenient】You can take it anytime, anywhere. 【Poor Stamina】【Poor Desire】【Early Fatigue Problem】【Infertility Problem】【Hormonal Problem】【Prostate Problem】【Poor Intimate Health Function】【Poor Sperm Quality】 will【gradually disappear】Learn more about the ingredients and other functions of this product, which will make you more excited.", " ",
  "【12 Types of Excellent Ingredients & Functions】", " ",
  "🫐 Blueberry Powder: Helps protect the heart, brain, and blood vessels.", " ",
  "🍇 Blackcurrant Powder: Promotes elastin and hyaluronic acid collagen and prevents skin aging and wrinkles.", " ",
  "🌱 Maca Powder: Improves intimate health, vitality, male fertility, and sperm quality; supports hormonal balance, testosterone, and prostate health.", " ",
  "🧅 L-Arginine: Improves intimate health, boosts exercise performance, and supports cardiovascular health, male fertility, and wound healing.", " ",
  "🥕 Tongkat Ali Extract: Increases testosterone levels, vitality, and intimate health; enhances fertility and sperm quality; boosts energy and stamina.", " ",
  "🌲 Synura: Supports prostate health and aids urinary health; enhances overall well-being for men.", " ",
  "🥜 Ginseng Peptide: Enhances intimate health; improves male fertility by regulating neurons; promotes spermatogenesis and acts on sperm via steroid receptors.", " ",
  "🍅 Lycopene Extract: Increases testosterone levels; improves sperm quality; improves male fertility; improves cardiovascular health.", " ",
  "🌿 L-Glutamine: Reduces muscle soreness, and aids recovery, increases testosterone and growth hormone levels.", " ",
  "🍃 β-nicotinamide Mononucleotide (NMN): Supplements nicotinamide adenine dinucleotide (NAD+) for healthy aging; benefits for cardiovascular health.", " ",
  "🍓 Vitamin & Mineral Premix: Provides and replenishes vitamins and minerals; enhances immune function and healthy metabolism.", " ",
  "🍊 Vitamin C: Repairs and regenerates tissue, protects skin health, and strengthens the immune system.", " ",
  "✅ KKM certification ✅ HALAL certification ✅ ISO certification ✅ GMP certification ✅ HACCP certification ✅ MESTI certification", " ",
  "【1 Box x 15 sachets】",
  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM88",
  "✔️ 2 boxes RM168",
  "✔️ 4 boxes RM328",
  "✔️ 6 boxes RM468",
  "✔️ 8 boxes RM598",
  "✔️ 10 boxes RM728", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const vcmasculiDescriptionCN = [
  "QNE VCMASCULI 🫐 蓝莓味的【男性保健】【100% 纯天然】不需要冲泡 【直接倒入嘴里】 【立马融化】【超级方便】随时随地都可以服用。服用之后【耐力不佳】【性欲不佳】【早泄问题】【不育问题】【睾丸问题】【前列腺问题】【勃起功能不佳】【精子质量不佳】都将【慢慢的离你而去】【一去不复返】心动了的话就往下了解此产品的成分和其他功效，绝对会让你更心动。", " ",
  "【12 种高品质的成分和功效】", " ",
  "🫐 蓝莓粉：含有抗氧化剂、丰富的膳食纤维、维生素的来源、有助于保护心脏、大脑和血管。", " ",
  "🍇 黑加仑粉：富含抗氧化剂、促进弹性蛋白和透明质酸胶原蛋白的生成、防止皮肤老化和皱纹。", " ",
  "🌱 玛卡粉：改善性功能、性欲、男性生育能力和精子质量、支持荷尔蒙平衡、睾酮和前列腺健康、增强能量和耐力、提高运动表现。", " ",
  "🧅 左-精氨酸：改善勃起功能、提高运动表现、支持心血管健康、男性生育能力和伤口愈合。", " ",
  "🥕 东革阿里提取物：提高睾酮水平、性欲和性能力、增强生育能力和精子质量、促进整体健康和活力、支持肌肉生长和力量、增强能量和耐力。", " ",
  "🌲 Synura：支持前列腺健康、并有助于泌尿健康、提供抗氧化剂、以及男性的整体福祉。", " ",
  "🥜 人参肽：增强性能力、通过调节神经元提高男性生育能力、促进精子发生并通过类固醇受体作用于精子、增强能量和耐力、支持认知功能和思维清晰度。", " ",
  "🍅 番茄红素提取物：提高睾酮水平、提高精子质量、提高男性生育能力、改善心血管健康。", " ",
  "🌿 谷氨酰胺：增强免疫系统、减少肌肉酸痛并帮助恢复、增加睾酮和生长激素水平。", " ",
  "🍃 β-烟酰胺单核苷酸 (NMN)：补充烟酰胺腺嘌呤二核苷酸 (NAD+)、促进健康老龄化、对心血管健康和认知健康有益、保护皮肤免受紫外线伤害、减缓衰老速度。", " ",
  "🍓 维生素和矿物质预混料：提供并补充维生素和矿物质、增强免疫功能和健康的新陈代谢。", " ",
  "🍊 维生素 C：对抗自由基、修复和再生组织、保护皮肤健康、增强免疫系统并改善整体健康。", " ",
  "✅ KKM 认证 ✅ HALAL 认证✅ ISO 认证 ✅ GMP 认证 ✅ HACCP 认证 ✅ MESTI 认证", " ",

  "【1 盒有 15 小包装】",
  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM88",
  "✔️ 2 盒 RM168",
  "✔️ 4 盒 RM328",
  "✔️ 6 盒 RM468",
  "✔️ 8 盒 RM598",
  "✔️ 10 盒 RM728", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];


const vcfeminiDescriptionEN = [
  "QNE VCFEMINI 🍒 Cranberry Flavored 【Women's Health】【100% Natural Ingredients】No need to brew 【Directly pour into mouth】【Dissolve immediately】【Super convenient】You can take it anytime, anywhere. 【Hormonal Imbalance】【Menstrual Discomfort】【Menstrual Irregularity】【Low Menstrual Flow】【Leucorrhea Problem】【Odor Problem】【Difficulty in Pregnancy】【Vaginitis】【Urethritis】【Low Desire】【Vaginal Laxity】【Breast Sagging】will【gradually disappear】Learn more about the ingredients and other functions of this product, which will make you more excited.", " ",
  "【13 Types of Excellent Ingredients & Functions】", " ",
  "🫐 Blackcurrant Powder: Promotes elastin and hyaluronic acid collagen and prevents skin aging and wrinkles.", " ",
  "🍒 Cranberry Powder: Anti-aging; improves heart health and prevents cancer and urinary tract infections.", " ",
  "🍎 Mixed Fruit Powder: Reduces damage to cells and strengthens the immune system.", " ",
  "🍃 Kacip Fatimah Extract: Maintains vaginal tone, elasticity, and firmness; improves endocrine; balances hormones, firm breasts; and increases desire.", " ",
  "🍑 Estro G: Promotes endometrial hyperplasia; helps relieve symptoms associated with menopause.", " ",
  "🥬 Licorice Extract: Invigorates the spleen and stomach; relieves dysmenorrhea.", " ",
  "🥕 Pueraria Lobata Extract: Stimulate the secretion of ovarian hormones and beautify skin; remove fat and lower blood pressure.", " ",
  "🍠 Red Beet Powder: Lowers cholesterol, promotes blood circulation, and protects the liver.", " ",
  "🍀 Manjakani Extract: Restores the elasticity of the uterine wall; tightens the vagina; protects the female genitals; tightens the breast muscle.", " ",
  "🫘 Soy Isoflavones: Phytoestrogen maintains bone health in postmenopausal women.", " ",
  "🍊 Vitamin C: Fights free radicals; repairs and regenerates tissue; protects skin health; strengthens the immune system.", " ",
  "🍃 β-nicotinamide Mononucleotide (NMN): Supplements nicotinamide adenine dinucleotide (NAD+) for healthy aging; benefits for cardiovascular health.", " ",
  "🍐 Probiotic (Bifidobacterium longum): Improve intestinal microorganisms balance; enhance the digestive tract.", " ",
  "✅ KKM certification ✅ HALAL certification ✅ ISO certification ✅ GMP certification ✅ HACCP certification ✅ MESTI certification", " ",

  "【1 Box x 15 sachets】",
  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM88",
  "✔️ 2 boxes RM168",
  "✔️ 4 boxes RM328",
  "✔️ 6 boxes RM468",
  "✔️ 8 boxes RM598",
  "✔️ 10 boxes RM728", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const vcfeminiDescriptionCN = [
  "QNE VCFEMINI 🍒 蔓越莓味的【女性保健】【100% 纯天然】不需要冲泡 【直接倒入嘴里】 【立马融化】【超级方便】随时随地都可以服用。服用之后【荷尔蒙失调】【经期不适】【月经失调】【月经量少】【白带问题】【异味问题】【怀孕困难】【阴道炎】【尿道炎】【性欲不佳】【阴道松弛】【胸部松弛】都将【慢慢的离你而去】【一去不复返】心动了的话就往下了解此产品的成分和其他功效，绝对会让你更心动。", " ",
  "【13 种高品质的成分和功效】", " ",
  "🫐 黑加仑粉：富含抗氧化、促进弹性蛋白和透明质酸胶原蛋白的生成、防止皮肤老化和皱纹。", " ",
  "🍒 蔓越莓粉：有助于抗衰老、减肥、增强骨骼和牙齿、改善心脏健康、预防癌症和尿路感染。", " ",
  "🍎 混合水果粉：减少细胞损伤、增强免疫系统。", " ",
  "🍃 卡琪花蒂玛提取物：保持阴道张力、弹性和紧致度、改善内分泌引起的微循环障碍、平衡体内荷尔蒙、坚挺乳房、增加性欲。", " ",
  "🍑 Estro G：促进子宫内膜增生、修复伤口并止血、有助于缓解与更年期相关的症状。", " ",
  "🥬 甘草提取物：健脾和胃、缓解痛经、高抗氧化性、美容肌肤、增强免疫力。", " ",
  "🥕 葛根提取物：刺激卵巢荷尔蒙的分泌、美化肌肤、去除脂肪、降低血压、改善心肌氧代谢。", " ",
  "🍠 红甜菜粉：促进消化健康、缓解便秘、降低胆固醇、促进血液循环、保护肝脏、肝脏解毒。", " ",
  "🍀 蔓加卡丽提取物：恢复子宫壁弹性、收紧阴道、保护女性生殖器免受阴道细菌和真菌感染、收紧胸部肌肉、防止下垂。", " ",
  "🫘 大豆异黄酮：植物雌激素、维持绝经后妇女的骨骼健康。", " ",
  "🍊 维生素 C：对抗自由基、修复和再生组织、保护皮肤健康、增强免疫系统并改善整体健康。", " ",
  "🍃 β-烟酰胺单核苷酸 (NMN)：补充烟酰胺腺嘌呤二核苷酸 (NAD+)、促进健康老龄化、对心血管健康和认知健康有益、保护皮肤免受紫外线伤害、减缓衰老速度。", " ",
  "🍐 益生菌 (双歧杆菌)：调整肠道菌群组成、抑制有害细菌、改善肠道微生物平衡、增强消化道的抗疫能力。", " ",
  "✅ KKM 认证 ✅ HALAL 认证✅ ISO 认证 ✅ GMP 认证 ✅ HACCP 认证 ✅ MESTI 认证", " ",

  "【1 盒有 15 小包装】",
  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM88",
  "✔️ 2 盒 RM168",
  "✔️ 4 盒 RM328",
  "✔️ 6 盒 RM468",
  "✔️ 8 盒 RM598",
  "✔️ 10 盒 RM728", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];

export const data = {
  vcbiotic: { CN: vcbioticDescriptionCN, EN: vcbioticDescriptionEN },
  vcmmune: { CN: vcmmuneDescriptionCN, EN: vcmmuneDescriptionEN },
  vcllagen: { CN: vcllagenDescriptionCN, EN: vcllagenDescriptionEN },
  vcblock: { CN: vcblockDescriptionCN, EN: vcblockDescriptionEN },
  vcmasculi: { CN: vcmasculiDescriptionCN, EN: vcmasculiDescriptionEN },
  vcfemini: { CN: vcfeminiDescriptionCN, EN: vcfeminiDescriptionEN },
};
